import { tw } from '@/utils/tw';
import BaseAdunitTheme from 'base/components/Adunit/theme';

const Adunit = tw.theme({
  extend: BaseAdunitTheme,
  slots: {
    base: `z-10 my-6 w-full bg-gray-50 pt-3 wings wings-gray-50 text-body-sm md:pt-6`,
    wrapper: `pt-1 md:pb-6`,
  },
});

export default Adunit;
